// @flow
import './index.less';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Markdown from 'react-markdown';

const PrivacySection = (): React$Node => {
  const { t } = useTranslation();

  return (
    <div className="privacy-container">
      <Markdown className="content">{t('Privacy_Content')}</Markdown>
    </div>
  );
};

export default PrivacySection;
